import React, { cloneElement, FC, Fragment, ReactElement, useMemo } from 'react';

interface TranslateProps {
  components: Record<string, ReactElement>;
  message: string;
}

const Translate: FC<TranslateProps> = ({ components, message }) => {
  const translatedMessage = useMemo(() => {
    const messageParts = [];
    let index = 0;

    for (const match of Array.from(message.matchAll(/<(\w+)>(.*?)<\/\1>/gm))) {
      const [capture, key, content] = match;
      const component = components[key];

      if (!component) {
        throw new Error(`A component with the key "${key}" was not defined`);
      }

      if (match.index !== undefined) {
        if (match.index !== 0) {
          messageParts.push(message.substring(index, match.index));
        }

        const children = content.length ? (
          <Translate components={components} message={content} />
        ) : null;

        messageParts.push(cloneElement(component, {}, children));

        index = match.index + capture.length;
      }
    }

    messageParts.push(message.substring(index));

    return messageParts;
  }, [components, message]);

  return (
    <>
      {translatedMessage.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}
    </>
  );
};

export default Translate;
