import { AuthType } from '@helpers/AuthClient';
import GoogleLoginView from '@views/GoogleLoginView';
import { navigate } from 'gatsby';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { DASHBOARD_PAGE } from '../../../constants';

const OauthGooglePage: FC = () => {
  const handleSuccess = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);

    navigate(searchParams.get('continueTo') ?? DASHBOARD_PAGE);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <GoogleLoginView authType={AuthType.Web} onSuccess={handleSuccess} />
    </>
  );
};

export default OauthGooglePage;
